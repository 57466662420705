



























import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/common/Header.vue';
import { searchConfigService } from '@/services/SearchConfigService';

@Component({
  components: { Header },
})
export default class Search extends Vue {
  data: any = [];
  type = '';
  isLoading = true;
  isError = false;
  mounted() {
    this.type = this.$route.params.type || '';
    this.list();
  }
  list() {
    this.isError = false;
    this.isLoading = true;
    searchConfigService
      .list()
      .then(data => {
        this.data = data;
        this.isLoading = false;
      })
      .catch(() => {
        this.isError = true;
        this.isLoading = false;
      });
  }
  goTo(path: string) {
    this.type = path;
    this.$router.push(`/search/${path}`).catch(() => {});
  }
}
